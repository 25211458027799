import React from 'react';
import Layout from '../layouts/index';
import { Link } from 'gatsby';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
          <div className="row justify-content-center">
            <div className="col-12">
              <h1>404 - Nicht gefunden</h1>
              <p>Entschuldigung, wird können diese Seite nicht finden.</p>
              <Link className="button button-primary mt-2" to="/">
                Zur Startseite
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
